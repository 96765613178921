import React, { useState } from "react";
import swal from "sweetalert";

const Contact = () => {
  const [data, setData] = useState({
    fullname: "",
    email: "",
    msg: "",
  });

  const inputEvent = (e) => {
    const { name, value } = e.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmitHandle = (e) => {
    e.preventDefault();
    // message can be saved to db or email can be sent from here!

    swal("Sent!", "Message Sent Successfully!", "success");
  };

  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="text-center mb-4">Contact Us</h1>
            <p className="lead text-center mb-4">
              Have questions or need assistance?
            </p>
            <div className="text-center">
              <p className="mb-0">
                <strong>Email:</strong>{" "}
                <a href="mailto:info@codesk.lk">info@codesk.lk</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auto">
            <form onSubmit={formSubmitHandle}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="fullname"
                  value={data.fullname}
                  onChange={inputEvent}
                  placeholder="Enter your name"
                />
                <label htmlFor="exampleFormControlInput1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="email"
                  value={data.email}
                  onChange={inputEvent}
                  placeholder="name@example.com"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="msg"
                  value={data.msg}
                  onChange={inputEvent}
                ></textarea>
                <div className="col-12">
                  <button className="btn btn-outline-primary mt-3" type="submit">
                    Submit form
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Contact;
