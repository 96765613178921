import React from 'react';

const Card = ({ 
    imgsrc,
    title,
    description,
    url
}) => {

    return (
        <>
            <div className="col-md-4 col-10 mx-auto">
                <div className="card">
                    <img src={imgsrc} className="card-img-top" id="coverImg" alt="card" />
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                        <p className="card-text">{description}</p>
                        <a href={url} className="btn btn-primary" target="_blank" rel="noreferrer">Website</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card;
