import gtl from "../images/gtl.png";
import me2works from "../images/me2works.png";

const ServiceData = [
    {
        imgsrc: me2works,
        title: "Me2Works",
        description: "Me2Works is a company dedicated to bridging the gap between experienced professionals and organizations in need of their expertise. Our mission is to provide a platform where mature professionals can continue contributing to society and companies, while also supporting startups with limited budgets to achieve success.",
        url: "https://www.me2works.com"
    },
    {
        imgsrc: gtl,
        title: "GTL",
        description: "GTL is an innovative group company focusing on ecological  and environmental protection technology, actively  responding to the national \"dual carbon strategy\",  promoting the international development of  environmental protection vigorously.        ",
        url: "https://www.gtlhk.com/"
    },
];

export default ServiceData;